<script setup lang="ts">
import { tv } from "tailwind-variants"

defineProps<{
  left?: boolean
  sm?: boolean
}>()

const titleClass = tv({
  base: "text-section font-semibold text-ebony-700 break-words",
  variants: {
    left: {
      true: "text-left",
      false: "text-center mx-auto max-w-xs",
    },
    sm: {
      true: "text-wordmark",
      false: "text-section",
    },
  },
})
</script>

<template>
  <h2 :class="titleClass({ left, sm })">
    <slot />
  </h2>
</template>
